import { store } from "./store/store";
import dayjs from "dayjs";
import { isLoggedIn } from "./isLoggedIn";
import { getMondayForSpark } from "@greenflagdate/shared";

export const getCurrentSparkPage = () => {
  const {
    data: { sparkRegistration, user, sparksPausedUntil },
  } = store;
  const currentMonday = getMondayForSpark(undefined, sparksPausedUntil);
  const currentKey = currentMonday.format("YYYY-MM-DD");
  if (sparkRegistration.registrationKey === currentKey) {
    return "/spark/preferences";
  }
  if (!sparkRegistration.activities?.length) {
    return "/spark/activities";
  }
  if (
    !sparkRegistration.availableDays?.some((d) =>
      dayjs(d).isAfter(currentMonday)
    )
  ) {
    return "/spark/availability";
  }
  if (!sparkRegistration.neighborhoods?.length) {
    return "/spark/neighborhoods";
  }
  const birthDate = new Date(
    user?.birth_date || sparkRegistration.birthDate || ""
  );
  if (isNaN(birthDate.getTime()) || birthDate.getFullYear() === 0) {
    return "/spark/birth-date";
  }
  if (!user?.gender && !sparkRegistration.gender) {
    return "/spark/gender";
  }
  if (!user?.gender_preference && !sparkRegistration.genderPreference) {
    return "/spark/gender";
  }
  if (!user?.first_name && !sparkRegistration.firstName) {
    return "/spark/name";
  }
  if (!isLoggedIn(store.data) || !user?.email) {
    return "/spark/email";
  }
  if (!("canLead" in sparkRegistration)) {
    return "/spark/leader";
  }
  return "/spark/checkout";
};
