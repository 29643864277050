import { Pane, Button } from "evergreen-ui";
import { useCallback, useMemo } from "react";
import { store } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { useNavigate } from "../../../lib/useNavigate";
import { useOutletContext } from "react-router-dom";
import { ProgressScreen } from "../../../components/ProgressScreen/ProgressScreen";
import { AppContext } from "../../app/App/appTypes";
import { VerticalGroup } from "../../../components/VerticalGroup/VerticalGroup";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

import { sparkScreenMap } from "./sparkScreens";
import { ToggleButton } from "../../../components/ToggleButton/ToggleButton";
import { useMondayForSpark, useSparkDates } from "../../../lib/useSparkDates";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

export const SparkAvailability = () => {
  const navigate = useNavigate();
  const {
    storeData: { sparkRegistration },
  } = useOutletContext<AppContext>();
  const availableDays = useMemo(
    () => sparkRegistration.availableDays || [],
    [sparkRegistration.availableDays]
  );
  const next = useCallback(async () => {
    navigate(`/spark/${sparkScreenMap["availability"].next}`);
  }, [navigate]);

  const options = useSparkDates();

  const toggle = useCallback(
    (val: dayjs.Dayjs) => () => {
      const iso = val.toISOString();
      store.dispatch({
        type: ActionType.UpdateSparkRegistration,
        params: {
          availableDays: availableDays.includes(iso)
            ? availableDays.filter((n) => n !== iso)
            : [...availableDays, iso],
        },
      });
    },
    [availableDays]
  );
  const mondayForSpark = useMondayForSpark();

  return (
    <ProgressScreen
      title="When are you available?"
      progress={sparkScreenMap["availability"].progress}
      prev={`/spark/${sparkScreenMap["availability"].prev}`}
      canContinue={availableDays.some((d) => dayjs(d).isAfter(mondayForSpark))}
      titleAlign="center"
      onContinue={next}
    >
      <Pane
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        alignItems="flex-end"
      >
        <Button
          size="small"
          onClick={() => {
            store.dispatch({
              type: ActionType.UpdateSparkRegistration,
              params: {
                availableDays:
                  availableDays.length === options.length
                    ? []
                    : [...options.map((o) => o.toISOString())],
              },
            });
          }}
        >
          {availableDays.length === options.length
            ? "Select None"
            : "Select All"}
        </Button>
        <VerticalGroup width="100%">
          {options.map((d) => {
            return (
              <ToggleButton
                key={d.toISOString()}
                type="button"
                width="100%"
                onClick={toggle(d)}
                isActive={availableDays.includes(d.toISOString())}
              >
                {d.format("dddd, MMM D @ h:mm a")}
              </ToggleButton>
            );
          })}
        </VerticalGroup>
      </Pane>
    </ProgressScreen>
  );
};
