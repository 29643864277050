import { Pane, Paragraph } from "evergreen-ui";

export const CancellationMessage = ({ isRefundable, isFreeEvent }: { isRefundable: boolean, isFreeEvent: boolean }) => {
    let content = null;
    if (isFreeEvent) {
        content = <Pane><Paragraph size={500} marginBottom="1rem">
            We’re sorry you won’t be able to make it! We’d love to have you join us at a future event. Keep your eye on our events page for more awesome opportunities to connect!
        </Paragraph>
        </Pane>;
    }
    else if (isRefundable) {
        content = <Pane><Paragraph size={500} marginBottom="1rem">
            We’d hate for you to miss out on this event—it’s shaping up to be a fantastic time with great people and exciting activities.
        </Paragraph>

            <Paragraph size={500}>
                <strong>If you still want to cancel, no problem—you’re eligible for a full refund. </strong>
            </Paragraph></Pane>;
    }
    else {
        content = <Pane>
            <Paragraph size={500} marginBottom="1rem">
                <strong>We’re unable to provide a refund for cancellations made within 48 hours of the event,</strong> as we’ve already made arrangements with the venue and carefully plan attendee ratios to ensure a great experience for everyone.
            </Paragraph>
        </Pane>;
    }

    return content;
};