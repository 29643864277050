import { Button, ButtonProps } from "evergreen-ui";
import { useState } from "react";
import { CancelEventRegistrationConfirmationDialog } from "../CancelEventRegistrationConfirmationDialog/CancelEventRegistrationConfirmationDialog";
import { Spark } from "@greenflagdate/shared";

interface CancelEventRegistrationButtonProps extends ButtonProps {
  onCancelReservation: () => void;
  spark: Spark;
}

export const CancelEventRegistrationButton = ({
  onCancelReservation,
  spark,
  ...props
}: CancelEventRegistrationButtonProps) => {
  const [shown, setShown] = useState(false);
  return (
    <>
      <Button {...props} onClick={() => setShown(true)}>
        Cancel Registration
      </Button>
      <CancelEventRegistrationConfirmationDialog
        onCancelReservation={onCancelReservation}
        onCloseComplete={() => setShown(false)}
        isShown={shown}
        spark={spark}
      />
    </>
  );
};
