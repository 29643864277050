import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useStoreData } from "./store/store";
import { getMondayForSpark } from "@greenflagdate/shared";

const getSparkDates = (mondayForSpark: Dayjs) => {
  const options = [];
  for (let i = 0; i < 7; i++) {
    const day = mondayForSpark.add(i, "days");
    if (i === 5 || i === 6) {
      options.push(day.set("hour", 11));
      options.push(day.set("hour", 18));
    }
    options.push(day.set("hour", 19));
  }
  return options;
};

export const useSparkDates = (now: string | undefined = undefined) => {
  const monday = useMondayForSpark();
  const [dates, setDates] = useState<Dayjs[]>(getSparkDates(monday));

  useEffect(() => {
    const msUntilTomorrow = dayjs(now).diff(
      dayjs(now).add(1, "day").startOf("day"),
      "milliseconds"
    );
    const timer = setTimeout(() => {
      setDates(getSparkDates(monday));
    }, msUntilTomorrow);
    return () => clearTimeout(timer);
  }, [monday, now]);

  return dates;
};

export const useMondayForSpark = (now: string | undefined = undefined) => {
  const { sparksPausedUntil } = useStoreData();
  return getMondayForSpark(now, sparksPausedUntil);
};
