import { Button, ButtonProps } from "evergreen-ui";
import { CancelSparkConfirmationDialog } from "../CancelSparkConfirmationDialog/CancelSparkConfirmationDialog";
import { useState } from "react";

interface CancelSparkButtonProps extends ButtonProps {
  onCancelReservation: () => void;
}

export const CancelSparkButton = ({
  onCancelReservation,
  ...props
}: CancelSparkButtonProps) => {
  const [shown, setShown] = useState(false);
  return (
    <>
      <Button {...props} onClick={() => setShown(true)}>
        Cancel Registration
      </Button>
      <CancelSparkConfirmationDialog
        onCancelReservation={onCancelReservation}
        onCloseComplete={() => setShown(false)}
        isShown={shown}
      />
    </>
  );
};
