import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { NotFound } from "../../../components/NotFound/NotFound";
import { useReq } from "@larner.dev/use-req";
import { apiReq } from "../../../lib/apiReq";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { LoaderScreen } from "../../../components/LoaderScreen/LoaderScreen";
import {
  FormField,
  Pane,
  Paragraph,
  TextInputField,
  Button as EButton,
  Alert,
} from "evergreen-ui";
import { PageHeader } from "../../../components/PageHeader/PageHeader";
import { Button } from "../../../components/Button/Button";
import {
  getDateTimeRangeString,
  PublicCredentialRecord,
  Spark,
  SparkRegistration,
  SparkWithRegistrationSummary,
  UserWithMediaAndGroups,
} from "@greenflagdate/shared";
import { Tag } from "../../../components/Tag/Tag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFire } from "@fortawesome/free-solid-svg-icons";
import { useGFTheme } from "../../../lib/themes";
import Markdown from "react-markdown";
import { H1, H2, H3 } from "../../../components/Heading/Heading";
import { NewCard } from "../../../components/Card/Card";
import { store, useStoreData } from "../../../lib/store/store";
import { ActionType } from "../../../lib/store/storeActions";
import { DateField } from "../../../components/DateField/DateField";
import { GenderSelector } from "../../../components/GenderSelector/GenderSelector";
import { GenderPreferenceSelector } from "../../../components/GenderPreferenceSelector/GenderPreferenceSelector";
import { LinkButton } from "../../../components/LinkButton/LinkButton";
import { LoginDialog } from "../../../components/LoginDialog/LoginDialog";
import { isLoggedIn } from "../../../lib/isLoggedIn";
import {
  PaymentForm,
  PaymentFormHandle,
} from "../../../components/PaymentForm/PaymentForm";
import { isEmail } from "validator";
import { VerificationCode } from "../../../components/VerificationCode/VerificationCode";
import { UserRequiredInfoForm } from "../../../components/UserRequiredInfoForm/UserRequiredInfoForm";
import { PhoneField } from "../../../components/PhoneField/PhoneField";
import libpn from "google-libphonenumber";
import { Meta } from "../../../components/Meta/Meta";
import {
  getAgeRangeString,
  getSparkErrorMessage,
} from "../../../lib/sparkHelpers";
import { CancelEventRegistrationButton } from "../../../components/CancelEventRegistrationButton/CancelSparkButton";

const phoneUtil = libpn.PhoneNumberUtil.getInstance();

dayjs.extend(utc);
dayjs.extend(timezone);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

interface RegisterErrors {
  email?: string;
  phone?: string;
  firstName?: string;
  birthDate?: string;
  gender?: string;
  genderPreference?: string;
  code?: string;
}

export const SparkDetails = () => {
  const [sparkReq, sparkReqState] =
    useReq<SparkWithRegistrationSummary>(apiReq);
  const [verifyReq, verifyReqState] = useReq<{
    token: string;
    user: UserWithMediaAndGroups;
  }>(apiReq);
  const [userReq, userReqState] = useReq<UserWithMediaAndGroups>(apiReq);
  const paymentFormRef = useRef<PaymentFormHandle>(null);
  const [paymentFormLoading, setPaymentFormLoading] = useState(false);

  const { colors } = useGFTheme();
  const { sparkId } = useParams();
  const storeData = useStoreData();
  const loggedIn = useMemo(() => isLoggedIn(storeData), [storeData]);
  const [loginDialogShown, setLoginDialogShown] = useState(false);
  const [newUserValidate, setNewUserValidate] = useState(false);
  const [registerErrors, setRegisterErrors] = useState<RegisterErrors>({});
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [registerReq, registerReqState] =
    useReq<PublicCredentialRecord>(apiReq);
  const [mySparksReq, mySparksReqState] = useReq<Spark[]>(apiReq);
  const [sparkRegReq, sparkRegReqState] = useReq<SparkRegistration>(apiReq);
  const firstName = useMemo(
    () => storeData.sparkRegistration?.firstName || "",
    [storeData.sparkRegistration?.firstName]
  );
  const [phone, setPhone] = useState("");
  const birthDate = useMemo(
    () => storeData.sparkRegistration.birthDate || "",
    [storeData.sparkRegistration.birthDate]
  );
  const date = useMemo(() => new Date(birthDate), [birthDate]);
  const validDate = !isNaN(date.getTime()) && date.getFullYear() !== 0;
  const age = useMemo(
    () => (validDate ? dayjs().diff(birthDate, "year") : 0),
    [birthDate, validDate]
  );
  const isRegistered = useMemo(
    () =>
      mySparksReqState.result?.some((s) => s.id === parseInt(sparkId || "")),
    [mySparksReqState.result, sparkId]
  );

  const isOver = useMemo(
    () => dayjs(sparkReqState.result?.starts_at).isBefore(dayjs()),
    [sparkReqState.result?.starts_at]
  );
  const isFree = useMemo(
    () => sparkReqState.result?.price === 0,
    [sparkReqState.result?.price]
  );

  const isFull = useMemo(
    () =>
      sparkReqState.result &&
      sparkReqState.result.capacity <=
      sparkReqState.result.registrationSummary.men +
      sparkReqState.result.registrationSummary.women +
      sparkReqState.result.registrationSummary.other,
    [sparkReqState.result]
  );

  useEffect(() => {
    if (loggedIn) {
      mySparksReq.get("/user/sparks");
    }
  }, [loggedIn, mySparksReq]);
  useEffect(() => {
    sparkReq.get(`/spark/${sparkId}`);
  }, [sparkId, sparkReq]);

  const doRegister = useCallback(async () => {
    const response = await sparkRegReq.post("/spark/register", {
      spark_id: sparkId,
    });
    if (response.success) {
      await mySparksReq.get("/user/sparks");
    }
  }, [mySparksReq, sparkId, sparkRegReq]);

  const verify = useCallback(
    async (otpOverride?: string) => {
      if (!storeData.auth.emailCredentialId) {
        setNewUserValidate(false);
        return;
      }
      const errors: RegisterErrors = {};
      if (!storeData.auth.email) {
        errors.email = "Please enter your email";
      } else if (!isEmail(storeData.auth.email)) {
        errors.email = "Please double check your email";
      }

      const otpVal = otpOverride || otp;

      if (!otpVal) {
        errors.code = "Please enter your code";
      }

      setRegisterErrors(errors);
      if (errors.code) {
        return;
      }

      if (Object.keys(errors).length) {
        setNewUserValidate(false);
        return;
      }
      verifyReqState.clearError();
      const response = await verifyReq.post(
        `/user/verify?source=spark-registration`,
        {
          token: `${otpVal}.${storeData.auth.emailCredentialId}`,
        }
      );

      if (response.success) {
        const { result } = response;
        store.dispatch({
          type: ActionType.Login,
          params: result,
        });
        const userResponse = await userReq.put("/user/attributes", {
          first_name: storeData.sparkRegistration.firstName,
          gender: storeData.sparkRegistration.gender,
          gender_preference: storeData.sparkRegistration.genderPreference,
          birth_date: storeData.sparkRegistration.birthDate,
          phone_number_to_validate: phone,
        });
        if (userResponse.success) {
          store.dispatch({
            type: ActionType.UpdateUser,
            params: {
              user: userResponse.result,
            },
          });
          if (isFree) {
            doRegister();
          }
        }
      }
    },
    [
      doRegister,
      isFree,
      otp,
      phone,
      storeData.auth.email,
      storeData.auth.emailCredentialId,
      storeData.sparkRegistration.birthDate,
      storeData.sparkRegistration.firstName,
      storeData.sparkRegistration.gender,
      storeData.sparkRegistration.genderPreference,
      userReq,
      verifyReq,
      verifyReqState,
    ]
  );
  const onOtpChange = useCallback(
    (otpVal: string) => {
      setOtp(otpVal);
      if (otpVal.length === 6) {
        verify(otpVal);
      }
    },
    [verify]
  );
  const womenSpots = useMemo(() => {
    if (sparkReqState.result?.capacity_women === null) {
      return null;
    }
    return sparkReqState.result
      ? Math.max(
        0,
        sparkReqState.result.capacity_women -
        sparkReqState.result.registrationSummary.women
      )
      : 0;
  }, [sparkReqState.result]);
  const menSpots = useMemo(() => {
    if (sparkReqState.result?.capacity_men === null) {
      return null;
    }
    return sparkReqState.result
      ? Math.max(
        0,
        sparkReqState.result.capacity_men -
        sparkReqState.result.registrationSummary.men
      )
      : 0;
  }, [sparkReqState.result]);
  const totalSpots = useMemo(() => {
    if (
      sparkReqState.result?.capacity_men !== null ||
      sparkReqState.result?.capacity_women !== null
    ) {
      return null;
    }
    return sparkReqState.result
      ? sparkReqState.result.capacity -
      sparkReqState.result.registrationSummary.men -
      sparkReqState.result.registrationSummary.women -
      sparkReqState.result.registrationSummary.other
      : 0;
  }, [sparkReqState.result]);
  const register = useCallback(async () => {
    const errors: RegisterErrors = {};
    if (!storeData.sparkRegistration.firstName) {
      errors.firstName = "Please enter your first name";
    }
    if (!storeData.auth.email) {
      errors.email = "Please enter your email";
    } else if (!isEmail(storeData.auth.email)) {
      errors.email = "Please double check your email";
    }
    if (!storeData.sparkRegistration.birthDate) {
      errors.birthDate = "Please enter your birth date";
    }
    if (age < 18) {
      errors.birthDate = "You must be 18 years or older to use Green Flag";
    }
    if (!storeData.sparkRegistration.gender) {
      errors.gender = "Please enter your gender";
    }
    if (!storeData.sparkRegistration.genderPreference) {
      errors.genderPreference = "Who do you want to date?";
    }
    if (
      storeData.sparkRegistration.gender === "man" &&
      menSpots !== null &&
      menSpots <= 0
    ) {
      errors.gender = "This event is full for men";
    }
    if (
      storeData.sparkRegistration.gender === "woman" &&
      womenSpots !== null &&
      womenSpots <= 0
    ) {
      errors.gender = "This event is full for women";
    }
    let parsedNewPhoneNumber;
    try {
      parsedNewPhoneNumber = phoneUtil.parse(phone, "US");
    } catch (error) {
      errors.phone = "It looks like this phone number is invalid";
    }

    if (
      parsedNewPhoneNumber &&
      !phoneUtil.isValidNumberForRegion(parsedNewPhoneNumber, "US")
    ) {
      errors.phone = "It looks like this phone number is invalid";
    }

    setRegisterErrors(errors);
    if (!Object.keys(errors).length) {
      registerReqState.clearError();
      const response = await registerReq.post("/user", {
        email: storeData.auth.email,
      });
      if (response.success) {
        store.dispatch({
          type: ActionType.UpdateAuth,
          params: {
            emailCredentialId: response.result.id,
          },
        });
        setOtp("");
        setNewUserValidate(true);
      }
    }
  }, [
    age,
    menSpots,
    phone,
    registerReq,
    registerReqState,
    storeData.auth.email,
    storeData.sparkRegistration.birthDate,
    storeData.sparkRegistration.firstName,
    storeData.sparkRegistration.gender,
    storeData.sparkRegistration.genderPreference,
    womenSpots,
  ]);

  if (sparkReqState.loading) {
    return <LoaderScreen />;
  }
  if (sparkReqState.error || !sparkReqState.result) {
    return <NotFound />;
  }
  const spark = sparkReqState.result;

  return (
    <Pane>
      <Meta>
        <title>{spark.title}</title>
        <meta property="og:title" content={spark.title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.greenflagdate.com/spark/atlanta/${sparkId}`}
        />
        {spark.primary_image && (
          <meta property="og:image" content={spark.primary_image} />
        )}
        {spark.primary_image && (
          <meta property="og:image:width" content="500" />
        )}
        {spark.primary_image && (
          <meta property="og:image:height" content="500" />
        )}
      </Meta>
      <PageHeader>
        <PageHeader.Title whiteSpace="pre-wrap">
          {sparkReqState.result.title}
        </PageHeader.Title>
        <PageHeader.Subtitle>
          {dayjs(spark.starts_at).format("ddd, MMM D @ h:mm A")} |{" "}
          <Pane
            is="a"
            href={`https://www.google.com/maps/place/?q=place_id:${spark.google_maps_place_id}`}
            target="_blank"
            textDecoration="underline"
          >
            {spark.location}
          </Pane>
        </PageHeader.Subtitle>
        {isOver ? (
          <H3>This event has concluded</H3>
        ) : isRegistered ? (
          <H3>You are registered</H3>
        ) : isFull ? (
          <H3>This event is full</H3>
        ) : (
          <Button
            label={"REGISTER"}
            size="medium"
            type="submit"
            href={`#join`}
            component="hash-link"
          />
        )}
      </PageHeader>
      <Pane
        width="100%"
        maxWidth="40rem"
        marginX="auto"
        paddingY="2rem"
        paddingX="1rem"
      >
        <Pane display="flex" gap="1rem" marginTop="0.5rem">
          {womenSpots !== null && (
            <Tag
              iconAfter={
                <FontAwesomeIcon
                  icon={faFire}
                  color={colors.orangePrimary}
                  fontSize="0.75rem"
                />
              }
              backgroundColor={colors.orangePrimary + "5A"}
            >
              <Pane is="span" fontWeight={300}>
                <Pane is="span" fontWeight="bold">
                  {womenSpots} spots
                </Pane>{" "}
                left for Women
              </Pane>
            </Tag>
          )}
          {menSpots !== null && (
            <Tag
              iconAfter={
                <FontAwesomeIcon
                  icon={faFire}
                  color={colors.orangePrimary}
                  fontSize="0.75rem"
                />
              }
              backgroundColor={colors.orangePrimary + "5A"}
            >
              <Pane is="span" fontWeight={300}>
                <Pane is="span" fontWeight="bold">
                  {menSpots} spots
                </Pane>{" "}
                left for Men
              </Pane>
            </Tag>
          )}
          {totalSpots !== null && (
            <Tag
              iconAfter={
                <FontAwesomeIcon
                  icon={faFire}
                  color={colors.orangePrimary}
                  fontSize="0.75rem"
                />
              }
              backgroundColor={colors.orangePrimary + "5A"}
            >
              <Pane is="span" fontWeight={300}>
                <Pane is="span" fontWeight="bold">
                  {totalSpots} spots
                </Pane>{" "}
                left
              </Pane>
            </Tag>
          )}
        </Pane>
        <Paragraph size={400} marginTop="0.5rem">
          ATTENDANCE LIMIT: {spark.capacity}
        </Paragraph>
        {spark.price > 0 && (
          <Paragraph size={400}>
            ADMISSION: {currencyFormatter.format(spark.price)}
          </Paragraph>
        )}
        <Pane marginTop="1rem" selectors={{ "& img": { width: "100%" } }}>
          <Markdown
            components={{
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              h1({ is, ref, ...props }) {
                return <H1 {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              h2({ is, ref, ...props }) {
                return <H2 {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              h3({ is, ref, ...props }) {
                return <H3 {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              p({ is, ref, ...props }) {
                return (
                  <Paragraph
                    size={500}
                    marginY="1rem"
                    lineHeight="1.6"
                    color={colors.greenDark}
                    {...props}
                  />
                );
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              a({ is, ref, href, ...props }) {
                return (
                  <Pane
                    is={Link}
                    to={href || ""}
                    color={colors.bluePrimary}
                    {...props}
                  />
                );
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ul({ is, ref, ...props }) {
                return <Pane is="ul" marginY="1rem" {...props} />;
              },
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              li({ is, ref, ...props }) {
                return (
                  <Pane is="li" marginY="0.25rem" marginX="1rem" {...props} />
                );
              },
            }}
          >
            {spark.description
              .replace(
                /\{AGE_RANGE\}/g,
                getAgeRangeString(spark.min_age, spark.max_age)
              )
              .replace(
                /\{TIME_RANGE\}/g,
                getDateTimeRangeString(
                  dayjs(spark.starts_at),
                  dayjs(spark.ends_at)
                )
              )}
          </Markdown>
        </Pane>
      </Pane>
      {!isOver && !isFull && (
        <>
          <a id="join" style={{ position: "relative", top: "-5rem" }} />
          <Pane background={colors.greenLight} paddingY="2rem">
            <NewCard width="100%" maxWidth="40rem" marginX="auto">
              <H2 marginBottom="1rem">
                {isRegistered ? "You're registered!" : "Join the event"}
              </H2>
              {loggedIn ? (
                <Pane
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Pane
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                  >
                    <Pane fontWeight="bold" color="#A5A5A5">
                      Hi, {storeData.user?.first_name}!
                    </Pane>
                    <Pane fontSize="0.75rem">
                      Not {storeData.user?.first_name}?&nbsp;
                      <LinkButton
                        fontSize="0.75rem"
                        onClick={() => {
                          store.dispatch({
                            type: ActionType.LogOut,
                            params: {},
                          });
                        }}
                      >
                        Log out
                      </LinkButton>
                    </Pane>
                  </Pane>
                  {isRegistered ? (
                    <>
                      <Paragraph size={500} width="100%" marginTop="1rem">
                        You're registered for this spark! If you have any
                        questions about the event, feel free to{" "}
                        <LinkButton onClick={() => navigate("/contact")}>
                          contact us
                        </LinkButton>
                        .
                      </Paragraph>
                      <Pane
                        width="100%"
                        display="flex"
                        alignItems="flex-start"
                        marginTop="1rem"
                      >
                        <CancelEventRegistrationButton
                          appearance="primary"
                          size="small"
                          backgroundColor={colors.redPrimary}
                          selectors={{
                            "&:hover": {
                              backgroundColor: colors.redDark,
                              color: "white",
                            },
                            "&": {
                              color: "black",
                            },
                          }}
                          onCancelReservation={() =>
                            mySparksReq.get("/user/sparks")
                          }
                          spark={sparkReqState.result}
                        />
                      </Pane>
                    </>
                  ) : !storeData.user?.first_name ||
                    !storeData.user?.birth_date ||
                    !storeData.user?.gender ||
                    !storeData.user?.gender_preference ||
                    (!storeData.user?.phone_number_to_validate &&
                      !storeData.user?.phone_number) ? (
                    <UserRequiredInfoForm
                      width="100%"
                      marginTop="1rem"
                      buttonLabel="Continue"
                    />
                  ) : (
                    <>
                      {sparkRegReqState.error && (
                        <Alert
                          title={getSparkErrorMessage(
                            sparkRegReqState.error.code,
                            storeData.user!,
                            spark,
                            sparkRegReqState.error.params
                          )}
                          width="100%"
                          intent="danger"
                          marginTop="1rem"
                        />
                      )}
                      {!isFree && (
                        <>
                          <Pane
                            fontSize="0.75rem"
                            paddingY="1rem"
                            paddingX="0.25rem"
                            display="flex"
                            flexDirection="column"
                            gap="0.5rem"
                            width="100%"
                          >
                            <Pane display="flex" justifyContent="space-between">
                              <Pane>{sparkReqState.result.title}</Pane>
                              <Pane>
                                {currencyFormatter.format(
                                  sparkReqState.result.price
                                )}
                              </Pane>
                            </Pane>
                            <Pane display="flex" justifyContent="space-between">
                              <Pane>Tax</Pane>
                              <Pane>
                                {currencyFormatter.format(
                                  sparkReqState.result.price * 0.089
                                )}
                              </Pane>
                            </Pane>
                            <Pane
                              display="flex"
                              justifyContent="space-between"
                              borderTop="1px solid black"
                            >
                              <Pane>Total</Pane>
                              <Pane>
                                {currencyFormatter.format(
                                  sparkReqState.result.price * 1.089
                                )}
                              </Pane>
                            </Pane>
                          </Pane>
                          <PaymentForm
                            ref={paymentFormRef}
                            width="100%"
                            onLoadingStatusChange={setPaymentFormLoading}
                            onSubmit={async () => {
                              if (!storeData.user?.phone_number_to_validate) {
                                let parsedNewPhoneNumber;
                                try {
                                  parsedNewPhoneNumber = phoneUtil.parse(
                                    phone,
                                    "US"
                                  );
                                } catch (error) {
                                  return setRegisterErrors({
                                    phone:
                                      "It looks like this phone number is invalid",
                                  });
                                }

                                if (
                                  parsedNewPhoneNumber &&
                                  !phoneUtil.isValidNumberForRegion(
                                    parsedNewPhoneNumber,
                                    "US"
                                  )
                                ) {
                                  return setRegisterErrors({
                                    phone:
                                      "It looks like this phone number is invalid",
                                  });
                                }

                                const response = await userReq.put(
                                  "/user/attributes",
                                  {
                                    phone_number_to_validate: phone,
                                  }
                                );
                                if (response.success) {
                                  store.dispatch({
                                    type: ActionType.UpdateUser,
                                    params: {
                                      user: response.result,
                                    },
                                  });
                                } else {
                                  return setRegisterErrors({
                                    phone:
                                      "There was a problem saving your phone number",
                                  });
                                }
                              }
                              await doRegister();
                            }}
                          />
                        </>
                      )}
                      <EButton
                        appearance="primary"
                        marginTop="2rem"
                        maxWidth="20rem"
                        isLoading={
                          paymentFormLoading ||
                          sparkRegReqState.loading ||
                          mySparksReqState.loading
                        }
                        marginX="auto"
                        width="100%"
                        onClick={() =>
                          isFree
                            ? doRegister()
                            : paymentFormRef.current?.submitForm()
                        }
                      >
                        REGISTER
                      </EButton>
                    </>
                  )}
                </Pane>
              ) : storeData.auth.emailCredentialId && newUserValidate ? (
                <Pane>
                  <Pane
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    paddingY="2rem"
                    maxWidth="20rem"
                    marginX="auto"
                  >
                    <H2>Verification Code</H2>
                    <Paragraph textAlign="center">
                      Please check your email ({storeData.auth.email}) and enter
                      the code that was sent to you.
                    </Paragraph>
                    <VerificationCode
                      value={otp}
                      onChange={onOtpChange}
                      length={6}
                    />
                  </Pane>
                  <Pane display="flex" gap="1rem" paddingY="2rem">
                    <EButton
                      appearance="minimal"
                      flex={1}
                      onClick={() => setNewUserValidate(false)}
                      type="button"
                      disabled={verifyReqState.loading || userReqState.loading}
                    >
                      Cancel
                    </EButton>
                    <EButton
                      appearance="primary"
                      flex={2}
                      type="submit"
                      disabled={verifyReqState.loading || userReqState.loading}
                    >
                      Continue
                    </EButton>
                  </Pane>
                </Pane>
              ) : (
                <>
                  <Pane>
                    Already have an account?&nbsp;
                    <LinkButton onClick={() => setLoginDialogShown(true)}>
                      Sign in
                    </LinkButton>
                  </Pane>
                  <TextInputField
                    marginTop="1.5rem"
                    value={firstName}
                    placeholder="First Name"
                    label="First Name"
                    validationMessage={registerErrors.firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      store.dispatch({
                        type: ActionType.UpdateSparkRegistration,
                        params: {
                          firstName: e.target.value,
                        },
                      })
                    }
                  />
                  <TextInputField
                    label="Email"
                    value={storeData.auth.email || ""}
                    type="email"
                    placeholder="email@example.com"
                    validationMessage={registerErrors.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      store.dispatch({
                        type: ActionType.UpdateAuth,
                        params: {
                          email: e.target.value,
                        },
                      })
                    }
                  />
                  <PhoneField
                    label="Phone Number"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                    validationMessage={registerErrors.phone}
                    marginBottom="1.5rem"
                  />
                  <DateField
                    label="Birth Date"
                    value={birthDate}
                    onChange={(bd) => {
                      store.dispatch({
                        type: ActionType.UpdateSparkRegistration,
                        params: {
                          birthDate: bd,
                        },
                      });
                    }}
                    validationMessage={registerErrors.birthDate}
                  />

                  <FormField
                    label="What's your gender?"
                    marginTop="1.5rem"
                    validationMessage={
                      registerErrors.gender ||
                      (storeData.sparkRegistration?.gender === "man" &&
                        menSpots !== null &&
                        menSpots <= 0 &&
                        "This event is full for men") ||
                      (storeData.sparkRegistration?.gender === "woman" &&
                        womenSpots !== null &&
                        womenSpots <= 0 &&
                        "This event is full for women")
                    }
                  >
                    <GenderSelector
                      value={storeData.sparkRegistration?.gender}
                      onChange={(gender) => {
                        store.dispatch({
                          type: ActionType.UpdateSparkRegistration,
                          params: {
                            gender,
                          },
                        });
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { gender: _gender, ...otherErrors } =
                          registerErrors;
                        setRegisterErrors(otherErrors);
                      }}
                      appearance="horizontal"
                    />
                  </FormField>
                  <FormField
                    label="Who do you want to date?"
                    marginTop="1.5rem"
                    validationMessage={registerErrors.genderPreference}
                  >
                    <GenderPreferenceSelector
                      value={storeData.sparkRegistration.genderPreference}
                      onChange={(genderPreference) =>
                        store.dispatch({
                          type: ActionType.UpdateSparkRegistration,
                          params: {
                            genderPreference,
                          },
                        })
                      }
                      appearance="horizontal"
                    />
                  </FormField>
                  <Pane
                    marginTop="1.5rem"
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      label="REGISTER"
                      size="large"
                      style={{ width: "100%" }}
                      onClick={register}
                      loading={registerReqState.loading}
                    />
                  </Pane>
                </>
              )}
            </NewCard>
          </Pane>
          <LoginDialog
            isShown={loginDialogShown}
            onClose={() => setLoginDialogShown(false)}
          />
        </>
      )}
    </Pane>
  );
};
