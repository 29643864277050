import {
  Alert,
  Badge,
  BadgeProps,
  Button,
  FormField,
  IconButton,
  Pane,
  Paragraph,
  PlusIcon,
  TextInput,
} from "evergreen-ui";
import { H2, H3 } from "../../../components/Heading/Heading";
import {
  useSparkActivityData,
  useSparkNeighborhoodData,
} from "../../../lib/sparkHelpers";
import { Loader } from "../../../components/Loader/Loader";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLoggedIn } from "../../../lib/useLoggedIn";
import { useStoreData } from "../../../lib/store/store";
import { apiReq } from "../../../lib/apiReq";
import { useReq } from "@larner.dev/use-req";
import {
  DayOfWeek,
  DayOfWeekOffset,
  etZone,
  SparkPreference,
} from "@greenflagdate/shared";
import { NewCard } from "../../../components/Card/Card";
import { useGFTheme } from "../../../lib/themes";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";
import { useMondayForSpark } from "../../../lib/useSparkDates";
import { CancelSparkConfirmationDialog } from "../../../components/CancelSparkConfirmationDialog/CancelSparkConfirmationDialog";
import { LinkButton } from "../../../components/LinkButton/LinkButton";
import { useNavigate } from "react-router-dom";
import { CancelSparkButton } from "../../../components/CancelSparkButton/CancelSparkButton";
import { Link } from "../../../components/Link/Link";

dayjs.extend(utc);
dayjs.extend(timezone);

const SPARK_PREFERENCE_TIME_FORMAT = "dddd HH:mm:ss";

const OptionBadge = ({ children, ...props }: BadgeProps) => {
  const { colors } = useGFTheme();
  return (
    <Badge
      textTransform="none"
      fontWeight={400}
      marginX="0.5rem"
      marginY="0.375rem"
      cursor="pointer"
      selectors={{
        "&": {
          color: colors.greenDark,
        },
        "&.selected": {
          backgroundColor: colors.greenLight,
          color: "white",
        },
        "&:hover": {
          opacity: 0.8,
        },
      }}
      {...props}
    >
      {children}
    </Badge>
  );
};

interface Errors {
  availableDays?: string;
  neighborhoods?: string;
  activities?: string;
}

const getAvailableDays = (selectedTimes: string[], mondayForSpark: Dayjs) => {
  return selectedTimes.map((t) => {
    const pieces = t.split(" ");
    const timePieces = pieces[1].split(":").map((p) => parseInt(p));
    const offset = DayOfWeekOffset[pieces[0] as DayOfWeek];
    return mondayForSpark
      .tz(etZone)
      .add(offset, "days")
      .hour(timePieces[0])
      .minute(timePieces[1])
      .second(timePieces[2])
      .toISOString();
  });
};

const getSelectedTimes = (
  availableDays: string[] | undefined,
  mondayForSpark: Dayjs
) => {
  return (availableDays || [])
    .map((d) => dayjs(d).tz(etZone))
    .filter((d) => {
      const diff = d.diff(mondayForSpark, "days");
      return diff >= 0 && diff <= 8;
    })
    .map((d) => d.format(SPARK_PREFERENCE_TIME_FORMAT));
};

type CurrentPreferences = Partial<
  Omit<SparkPreference, "available_days"> & { selected_times: string[] }
>;

export const SparkPreferences = () => {
  const storeData = useStoreData();
  useLoggedIn(storeData);
  const { colors } = useGFTheme();
  const navigate = useNavigate();
  const { loading: sparkActivitiesLoading, sparkActivities } =
    useSparkActivityData();
  const { loading: sparkNeighborhoodsLoading, sparkNeighborhoods } =
    useSparkNeighborhoodData();

  const [savePrefReq, savePrefReqState] = useReq<SparkPreference>(apiReq);
  const [sparkSignupReq, sparkSignupReqState] = useReq<SparkPreference>(apiReq);
  const [latestPrefReq, latestPrefReqState] = useReq<SparkPreference | null>(
    apiReq
  );
  const [customActivity, setCustomActivity] = useState("");
  const [cancelConfirmationVisible, setCancelConfirmationVisible] =
    useState(false);
  const [currentPreferences, setCurrentPreferences] =
    useState<CurrentPreferences | null>(null);
  const [errors, setErrors] = useState<Errors>({});
  const mondayForSpark = useMondayForSpark();
  const currentKey = useMemo(
    () => mondayForSpark.format("YYYY-MM-DD"),
    [mondayForSpark]
  );

  const neighborhoods = useMemo(
    () => currentPreferences?.neighborhoods || [],
    [currentPreferences?.neighborhoods]
  );
  const activities = useMemo(
    () => currentPreferences?.activities || [],
    [currentPreferences?.activities]
  );
  const customActivities = useMemo(
    () =>
      (currentPreferences?.activities || []).filter(
        (activity) =>
          !sparkActivities.some(
            (cmsActivity) => cmsActivity.attributes.name === activity
          )
      ),
    [currentPreferences?.activities, sparkActivities]
  );
  const uniqueTimes = useMemo(() => {
    const uniqTimes = [
      ...new Set(storeData.sparkTimes.map((t) => t.timeOfDay)),
    ];
    uniqTimes.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
    return uniqTimes;
  }, [storeData.sparkTimes]);

  useEffect(() => {
    latestPrefReq.get("/spark/preferences/latest").then((res) => {
      if (res.success) {
        setCurrentPreferences({
          ...res.result,
          selected_times: (res.result?.available_days || []).map((d) =>
            dayjs(d).tz(etZone).format(SPARK_PREFERENCE_TIME_FORMAT)
          ),
        });
      } else {
        setCurrentPreferences(null);
      }
    });
  }, [latestPrefReq]);
  const updatePreferences = useCallback(
    async (update: CurrentPreferences) => {
      const newPreferences = { ...currentPreferences, ...update };
      setCurrentPreferences((existing) => ({ ...existing, ...update }));
      const newErrors = { ...errors };
      if (update.selected_times && update.selected_times.length) {
        delete newErrors.availableDays;
      }
      if (update.neighborhoods && update.neighborhoods.length) {
        delete newErrors.neighborhoods;
      }
      if (update.activities && update.activities.length) {
        delete newErrors.activities;
      }
      setErrors(newErrors);
      if (currentPreferences?.key === currentKey) {
        const availableDays = getAvailableDays(
          update.selected_times || [],
          mondayForSpark
        );
        const res = await savePrefReq.put("/spark/preference", {
          neighborhoods: newPreferences?.neighborhoods || [],
          availableDays,
          activities: newPreferences?.activities || [],
          canLead: newPreferences?.can_lead || false,
        });
        if (res.success) {
          setCurrentPreferences({
            ...res.result,
            selected_times: getSelectedTimes(
              res.result.available_days,
              mondayForSpark
            ),
          });
        }
      }
    },
    [currentKey, currentPreferences, errors, mondayForSpark, savePrefReq]
  );
  const sparkSignup = useCallback(async () => {
    const errors: Errors = {};
    const neighborhoods = currentPreferences?.neighborhoods || [];
    const activities = currentPreferences?.activities || [];

    if (!currentPreferences?.selected_times?.length) {
      errors.availableDays =
        "You must pick at least one day that you're available";
    }
    if (!neighborhoods.length) {
      errors.neighborhoods =
        "You must pick at least one neighborhood that you can travel to";
    }
    if (!activities.length) {
      errors.activities =
        "You must pick at least one activity that you're interested in";
    }
    setErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }

    const availableDays = getAvailableDays(
      currentPreferences?.selected_times || [],
      mondayForSpark
    );

    const res = await sparkSignupReq.put("/spark/preference", {
      neighborhoods: currentPreferences?.neighborhoods || [],
      availableDays,
      activities: currentPreferences?.activities || [],
      canLead: currentPreferences?.can_lead || false,
    });
    if (res.success) {
      setCurrentPreferences({
        ...res.result,
        selected_times: (res.result?.available_days || []).map((d) =>
          dayjs(d).tz(etZone).format(SPARK_PREFERENCE_TIME_FORMAT)
        ),
      });
    }
  }, [
    currentPreferences?.activities,
    currentPreferences?.can_lead,
    currentPreferences?.neighborhoods,
    currentPreferences?.selected_times,
    mondayForSpark,
    sparkSignupReq,
  ]);
  return (
    <Pane
      maxWidth="40rem"
      marginX="auto"
      marginTop="1.5rem"
      paddingX="1.5rem"
      paddingBottom="2rem"
    >
      <Pane display="flex">
        <H2 flex={1}>Spark Registration</H2>
      </Pane>
      {latestPrefReqState.loading ? (
        <Loader />
      ) : (
        <>
          {(!currentPreferences || currentPreferences.key !== currentKey) && (
            <Alert
              title="You aren't signed up for any sparks right now..."
              marginTop="1.5rem"
              selectors={{ "& h4": { fontSize: "1rem" } }}
            >
              <Button
                appearance="primary"
                size="small"
                marginTop="1rem"
                isLoading={sparkSignupReqState.loading}
                onClick={sparkSignup}
              >
                Sign me up for{" "}
                {mondayForSpark.isAfter(
                  dayjs.tz(undefined, etZone).endOf("week")
                ) &&
                mondayForSpark
                  .endOf("week")
                  .isBefore(
                    dayjs
                      .tz(undefined, etZone)
                      .endOf("week")
                      .add(1, "week")
                      .add(1, "second")
                  )
                  ? "next week"
                  : mondayForSpark.format("MMM D") +
                    " - " +
                    mondayForSpark.add(6, "days").format("MMM D")}
                !
              </Button>
            </Alert>
          )}
          {currentPreferences && currentPreferences.key === currentKey && (
            <Alert
              title={`You're signed up for ${
                mondayForSpark.isAfter(
                  dayjs.tz(undefined, etZone).endOf("week")
                ) &&
                mondayForSpark
                  .endOf("week")
                  .isBefore(
                    dayjs
                      .tz(undefined, etZone)
                      .endOf("week")
                      .add(1, "week")
                      .add(1, "second")
                  )
                  ? "next week"
                  : "the week of " +
                    mondayForSpark.format("MMM D") +
                    " - " +
                    mondayForSpark.add(6, "days").format("MMM D")
              }!`}
              marginTop="1.5rem"
              selectors={{ "& h4": { fontSize: "1rem" } }}
            >
              You can adjust your preferences below.
            </Alert>
          )}
          {!!savePrefReqState.error && (
            <Alert
              marginTop="1.5rem"
              title={
                savePrefReqState.error.code === "INVALID_AVAILABLE_DAYS" ? (
                  <>
                    <Pane>
                      You must select at least one day. Did you want to cancel?
                    </Pane>
                    <CancelSparkButton
                      marginTop="0.5rem"
                      size="small"
                      onCancelReservation={() => {
                        latestPrefReq
                          .get("/spark/preferences/latest")
                          .then((res) => {
                            if (res.success) {
                              setCurrentPreferences({
                                ...res.result,
                                selected_times: (
                                  res.result?.available_days || []
                                ).map((d) =>
                                  dayjs(d)
                                    .tz(etZone)
                                    .format(SPARK_PREFERENCE_TIME_FORMAT)
                                ),
                              });
                            } else {
                              setCurrentPreferences(null);
                            }
                            savePrefReqState.clearError();
                          });
                      }}
                    />
                  </>
                ) : (
                  savePrefReqState.error.message
                )
              }
              intent="danger"
            />
          )}
          {!!sparkSignupReqState.error && (
            <Alert
              marginTop="1.5rem"
              title={
                sparkSignupReqState.error.code === "NO_PAYMENT_METHOD" ? (
                  <>
                    <Pane>
                      Please enter your credit card information on the{" "}
                      <Link to={"/settings"}>Settings page</Link> before signing
                      up.
                    </Pane>
                  </>
                ) : (
                  sparkSignupReqState.error.message
                )
              }
              intent="danger"
            />
          )}
          <Pane display="flex" marginTop="1.5rem">
            <H3 flex={1}>Availability</H3>
            <LinkButton
              onClick={() =>
                updatePreferences({
                  selected_times:
                    currentPreferences?.selected_times?.length ===
                    storeData.sparkTimes.length
                      ? []
                      : storeData.sparkTimes.map(
                          (t) => `${t.dayOfWeek} ${t.timeOfDay}`
                        ),
                })
              }
            >
              {currentPreferences?.selected_times?.length ===
              storeData.sparkTimes.length
                ? "Select None"
                : "Select All"}
            </LinkButton>
          </Pane>
          <FormField validationMessage={errors.availableDays}>
            <NewCard
              padding={0}
              borderWidth="1px"
              borderColor={
                errors.availableDays ? colors.redPrimary : colors.neutralMedium
              }
              marginTop="1.25rem"
            >
              {Array.from({ length: 7 }, (_, i) => i).map((dayNumber) => {
                const day = mondayForSpark.add(dayNumber, "days");
                const times = storeData.sparkTimes.filter(
                  (t) => t.dayOfWeek === day.format("dddd")
                );
                return (
                  <Pane
                    display="flex"
                    selectors={{
                      "&:last-child > div:first-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Pane
                      color="#ADADAD"
                      textAlign="center"
                      borderBottom="1px solid #ADADAD"
                      paddingY="0.5rem"
                      fontSize="0.65rem"
                      width="2.875rem"
                      borderRight={`2px solid ${colors.neutralMedium}`}
                    >
                      {day.format("ddd D").toUpperCase()}
                    </Pane>
                    <Pane display="flex" flex={1}>
                      {uniqueTimes.map((uniqueTime) => {
                        const timePieces = uniqueTime
                          .split(":")
                          .map((t) => parseInt(t));
                        const dayAndTime = day
                          .hour(timePieces[0])
                          .minute(timePieces[1])
                          .second(timePieces[2]);
                        const timeString = `${day.format(
                          "dddd"
                        )} ${uniqueTime}`;

                        return times.some(
                          (time) => time.timeOfDay === uniqueTime
                        ) ? (
                          <OptionBadge
                            flex={1}
                            className={classNames({
                              selected:
                                currentPreferences?.selected_times?.includes(
                                  timeString
                                ),
                            })}
                            onClick={() => {
                              updatePreferences({
                                selected_times:
                                  currentPreferences?.selected_times?.includes(
                                    timeString
                                  )
                                    ? currentPreferences?.selected_times?.filter(
                                        (t) => t !== timeString
                                      )
                                    : [
                                        ...(currentPreferences?.selected_times ||
                                          []),
                                        timeString,
                                      ],
                              });
                            }}
                          >
                            {dayAndTime.format("h:mm A")}
                          </OptionBadge>
                        ) : (
                          <Pane flex={1} marginX="0.875rem" />
                        );
                      })}
                    </Pane>
                  </Pane>
                );
              })}
            </NewCard>
          </FormField>
          <Pane display="flex" marginTop="1.5rem">
            <H3 flex={1}>Neighborhoods</H3>
            <LinkButton
              onClick={() =>
                updatePreferences({
                  neighborhoods:
                    neighborhoods.length === sparkNeighborhoods.length
                      ? []
                      : sparkNeighborhoods.map(
                          (neighborhood) => neighborhood.attributes.name
                        ),
                })
              }
            >
              {neighborhoods.length === sparkNeighborhoods.length
                ? "Select None"
                : "Select All"}
            </LinkButton>
          </Pane>
          {sparkNeighborhoodsLoading ? (
            <Loader />
          ) : (
            <FormField validationMessage={errors.neighborhoods}>
              <NewCard
                display="flex"
                flexWrap="wrap"
                padding="2px"
                borderWidth="1px"
                borderColor={
                  errors.neighborhoods
                    ? colors.redPrimary
                    : colors.neutralMedium
                }
                marginTop="1.25rem"
              >
                {sparkNeighborhoods.map((neighborhood) => {
                  return (
                    <OptionBadge
                      className={classNames({
                        selected: neighborhoods.includes(
                          neighborhood.attributes.name
                        ),
                      })}
                      onClick={() => {
                        updatePreferences({
                          neighborhoods: neighborhoods.includes(
                            neighborhood.attributes.name
                          )
                            ? neighborhoods.filter(
                                (n) => n !== neighborhood.attributes.name
                              )
                            : [...neighborhoods, neighborhood.attributes.name],
                        });
                      }}
                    >
                      {neighborhood.attributes.name}
                    </OptionBadge>
                  );
                })}
              </NewCard>
            </FormField>
          )}
          <Pane display="flex" marginTop="1.5rem">
            <H3 flex={1}>Activities</H3>
            <LinkButton
              onClick={() =>
                updatePreferences({
                  activities:
                    activities.length === sparkActivities.length
                      ? []
                      : sparkActivities.map(
                          (activity) => activity.attributes.name
                        ),
                })
              }
            >
              {activities.length === sparkActivities.length
                ? "Select None"
                : "Select All"}
            </LinkButton>
          </Pane>
          {sparkActivitiesLoading ? (
            <Loader />
          ) : (
            <>
              <Pane
                display="flex"
                marginTop="1.25rem"
                marginBottom="0.25rem"
                gap="0.5rem"
                alignItems="center"
              >
                <H3 fontSize="1rem">Basic Events</H3>
                <Paragraph size={300}>Admission: $15</Paragraph>
              </Pane>
              <FormField validationMessage={errors.activities}>
                <NewCard
                  display="flex"
                  flexWrap="wrap"
                  padding="2px"
                  borderWidth="1px"
                  borderColor={
                    errors.activities ? colors.redPrimary : colors.neutralMedium
                  }
                >
                  {sparkActivities
                    .filter((activity) => !activity.attributes.isPremium)
                    .map((activity) => (
                      <OptionBadge
                        className={classNames({
                          selected: (
                            currentPreferences?.activities || []
                          ).includes(activity.attributes.name),
                        })}
                        onClick={() => {
                          updatePreferences({
                            activities: activities.includes(
                              activity.attributes.name
                            )
                              ? activities.filter(
                                  (n) => n !== activity.attributes.name
                                )
                              : [...activities, activity.attributes.name],
                          });
                        }}
                      >
                        {activity.attributes.name}
                      </OptionBadge>
                    ))}
                </NewCard>

                <Pane
                  display="flex"
                  marginTop="1.25rem"
                  marginBottom="0.25rem"
                  gap="0.5rem"
                  alignItems="center"
                >
                  <H3 fontSize="1rem">Premium Events</H3>
                  <Paragraph size={300}>Admission: $30</Paragraph>
                </Pane>
                <NewCard
                  display="flex"
                  flexWrap="wrap"
                  padding="2px"
                  borderWidth="1px"
                  borderColor={
                    errors.activities ? colors.redPrimary : colors.neutralMedium
                  }
                >
                  {sparkActivities
                    .filter((activity) => activity.attributes.isPremium)
                    .map((activity) => (
                      <OptionBadge
                        className={classNames({
                          selected: (
                            currentPreferences?.activities || []
                          ).includes(activity.attributes.name),
                        })}
                        onClick={() => {
                          updatePreferences({
                            activities: activities.includes(
                              activity.attributes.name
                            )
                              ? activities.filter(
                                  (n) => n !== activity.attributes.name
                                )
                              : [...activities, activity.attributes.name],
                          });
                        }}
                      >
                        {activity.attributes.name}
                      </OptionBadge>
                    ))}
                </NewCard>
                {!!customActivities.length && (
                  <>
                    <Pane
                      display="flex"
                      marginTop="1.25rem"
                      marginBottom="0.25rem"
                      gap="0.5rem"
                      alignItems="center"
                    >
                      <H3 fontSize="1rem">Your Suggestions</H3>
                    </Pane>
                    <NewCard
                      display="flex"
                      flexWrap="wrap"
                      padding="2px"
                      borderWidth="1px"
                      borderColor={colors.neutralMedium}
                    >
                      {customActivities.map((activity) => (
                        <OptionBadge
                          className={classNames({
                            selected: (
                              currentPreferences?.activities || []
                            ).includes(activity),
                          })}
                          onClick={() => {
                            updatePreferences({
                              activities: activities.includes(activity)
                                ? activities.filter((n) => n !== activity)
                                : [...activities, activity],
                            });
                          }}
                        >
                          {activity}
                        </OptionBadge>
                      ))}
                    </NewCard>
                  </>
                )}
              </FormField>
              <Pane
                is="form"
                display="flex"
                marginTop="1rem"
                onSubmit={(e: React.FormEvent) => {
                  e.preventDefault();
                  if (customActivity) {
                    updatePreferences({
                      activities: [...activities, customActivity],
                    });
                    setCustomActivity("");
                  }
                }}
              >
                <TextInput
                  width="100%"
                  placeholder="Add your own!"
                  borderTopRightRadius="0"
                  borderBottomRightRadius="0"
                  value={customActivity}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCustomActivity(e.target.value)
                  }
                />
                <IconButton
                  icon={<PlusIcon color="white" />}
                  appearance="primary"
                  borderRadius="5px"
                  borderTopLeftRadius="0"
                  borderBottomLeftRadius="0"
                  height="46px"
                />
              </Pane>
            </>
          )}
        </>
      )}
      <CancelSparkConfirmationDialog
        onCancelReservation={() => {
          setCurrentPreferences(null);
          navigate("/events");
        }}
        onCloseComplete={() => setCancelConfirmationVisible(false)}
        isShown={cancelConfirmationVisible}
      />
    </Pane>
  );
};
